<template>
    <el-dialog :visible.sync="visible" title="收款" width="450px" @close="close">
        <el-form ref="receiveForm" size="small" :rules="rules" :model="model" label-width="150px">
            <el-form-item label="应收总计（元）">
                <el-input :value="model.tAmount" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="已收总计（元）">
                <el-input :value="model.rAmount" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="剩余应收金额（元）">
                <el-input :value="model.tAmount - model.rAmount" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="本次收款金额（元）" prop="amount">
                <el-input v-model="model.amount"></el-input>
            </el-form-item>
            <el-form-item label="交易渠道" prop="payW">
                <el-select v-model="model.payW">
                    <el-option v-for="p in payWList" :key="p.value" :value="p.value" :label="p.label"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注">
                <el-input type="textarea" :rows="2" v-model="model.remark"></el-input>
            </el-form-item>
        </el-form>
        <div class="receive_buttons">
            <el-button size="small" type="primary" @click="makeSure">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            close() {
                this.model.amount = 0;
                this.model.payW = "";
                this.model.remark = "";
            },
            init(row) {
                this.visible = true;
                this.model.tallyId = row.tallyId;
                this.model.tAmount = row.amount || 0;
                this.model.rAmount = row.rAmount || 0;
            },
            makeSure() {
                this.$refs.receiveForm.validate(valide => {
                    if (valide) {
                        this.$http.post("/bill/verify/receiveMoney", this.model).then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.visible = false;
                            this.$emit("update");
                        });
                    }
                });
            }
        },
        data() {
            return {
                visible: false,

                model: {
                    payW: "",
                    tAmount: 0,
                    amount: 0,
                    remark: "",
                    rAmount: 0
                },
                rules: {
                    payW: [{ required: true, message: "交易渠道不为空", trigger: "change" }],
                    amount: [
                        { required: true, message: "本次收款金额不为空", trigger: "blur" },
                        {
                            validator: (rule, value, callback) => {
                                if (isNaN(value)) {
                                    return new Error("只能输入数字");
                                } else if (Number(value) + Number(this.model.rAmount) > Number(this.model.tAmount)) {
                                    callback(new Error("输入数字不能大于剩余应收金额"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "blur"
                        }
                    ]
                },
                payWList: [
                    {
                        label: "对公转账",
                        value: 0
                    },
                    {
                        label: "微信",
                        value: 1
                    },
                    {
                        label: "支付宝",
                        value: 2
                    },
                    {
                        label: "现金",
                        value: 3
                    }
                ]
            };
        }
    };
</script>
<style scoped>
    .receive_buttons {
        text-align: center;
    }
</style>
