<template>
    <el-dialog :visible.sync="visible" title="添加应用收费" width="80%">
        <jy-query ref="form" :model="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="单据编号" prop="receiptNo">
                <el-input v-model="form.receiptNo"></el-input>
            </jy-query-item>
            <jy-query-item label="出库分拣中心" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in dUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="利用方式" prop="usage">
                <el-select v-model="form.usage">
                    <el-option v-for="t in useTypes" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="回收类别" prop="recycleT">
                <div @click="selecrecycleTs">
                    <el-input placeholder="请选择" v-model="form.recycleTNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="物资名称" prop="materialNa">
                <el-input v-model="form.materialNa"></el-input>
            </jy-query-item>
            <jy-query-item label="出库时间" prop="createT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd HH:mm:ss" v-model="form.createT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')">重置</el-button>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table ref="accountTable" :data="tableData">
                <jy-table-column type="selection"></jy-table-column>
                <jy-table-column type="index" label="序号"></jy-table-column>
                <jy-table-column label="单据编号" prop="receiptNo"></jy-table-column>
                <jy-table-column label="出库时间" prop="createT"></jy-table-column>
                <jy-table-column label="出库分拣中心">
                    <template v-slot="scope">{{ formatUnit(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="利用单位" prop="uUnitId">
                    <template v-slot="scope">{{ formatUUnit(scope.row.uUnitId) }}</template>
                </jy-table-column>
                <jy-table-column label="利用方式" prop="usage"></jy-table-column>
                <jy-table-column label="出库物资">
                    <template v-slot="scope">{{ formatMaterial(scope.row.detailList) }}</template>
                </jy-table-column>
                <jy-table-column label="回收类别" prop="recycleTStr">
                    <template v-slot="scope">{{ formatRecycle(scope.row.detailList) }}</template>
                </jy-table-column>
                <jy-table-column label="合计">
                    <template v-slot="scope">{{ formatAmountTotal(scope.row.detailList) }}</template>
                </jy-table-column>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>

            <div class="account_buttons">
                <el-button type="primary" size="small" @click="makeSure">确定</el-button>
                <el-button size="small" @click="visible = false">取消</el-button>
            </div>

            <recycle-types-tree :modal="false" ref="recycle" @addInstitutions="addRecycleType"></recycle-types-tree>
        </div>
    </el-dialog>
</template>

<script>
    import RecycleTypesTree from "@/components/pages/admin/common/dialog-tree/recycleTypes_tree.vue";
    export default {
        methods: {
            init(unitId) {
                this.visible = true;
                this.form.uUnitId = unitId;
                this.getList();
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                this.$http
                    .post("/bill/pageQuery", {
                        hasDT: "1",
                        taStatus: "0",
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        ...this.form,
                        timeStart: this.form.createT[0],
                        timeEnd: this.form.createT[1],
                        createT: undefined
                    })
                    .then(res => {
                        this.tableData = res.detail.list;
                        this.total = res.detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.recycleTNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            addRecycleType(data) {
                this.form.recycleTNa = data.cName;
                this.form.recycleT = data.recycleTId;
            },
            // 选择机构树
            selecrecycleTs() {
                this.$refs.recycle.init();
            },
            initDicts() {
                this.$http.post("/dict/getDictValuesByDictId", "dictuseway").then(res => {
                    this.useTypes = res.detail;
                });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.dUnitList = res.detail;
                    });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "1"
                    })
                    .then(res => {
                        this.uUnitList = res.detail;
                    });
            },
            formatUUnit(id) {
                const w = this.uUnitList.find(v => v.unitId === id);
                return w ? w.cname : "-";
            },
            formatUnit(key) {
                const u = this.dUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatMaterial(list) {
                if (list && list.length) {
                    const mStrList = list.map(v => {
                        return v.material.cName + v.amount + "kg";
                    });
                    return mStrList.join(",");
                } else {
                    return "-";
                }
            },
            formatRecycle(list) {
                if (list && list.length) {
                    return list.map(v => v.material.recycleTStr).join(",");
                } else {
                    return "-";
                }
            },
            formatAmountTotal() {
                if (list && list.length) {
                    return list.reduce((total, cur) => {
                        return total + cur.price * cur.amount;
                    }, 0);
                } else {
                    return "-";
                }
            },
            makeSure() {
                const list = this.$refs.accountTable.selection;
                if (list.length) {
                    this.$emit("select", list);
                    this.visible = false;
                } else {
                    this.$message.success({
                        message: "请选择应收费用"
                    });
                }
            }
        },
        mounted() {
            this.initDicts();
        },
        data() {
            return {
                loading: false,
                visible: false,

                tableData: [],
                total: 0,
                wasteTypes: [],
                dUnitList: [],
                uUnitList: [],
                useTypes: [],

                form: {
                    receiptNo: "",
                    unitId: "",
                    uUnitId: "",
                    usage: "",
                    recycleT: "",
                    recycleTNa: "",
                    materialNa: "",
                    dUserNa: "",
                    createT: [],
                    DZReceiptNo: "",
                    hasDZReceiptNo: "",
                    verify: "",
                    hasRAmount: ""
                },

                pageIndex: 1,
                pageSize: 10
            };
        },
        components: {
            RecycleTypesTree
        }
    };
</script>
<style scoped>
    .account_buttons {
        text-align: center;
    }
</style>
