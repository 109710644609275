<template>
    <el-table :data="list">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="cName" label="物资名称">
            <template v-slot="scope">{{ scope.row.material.cName }}</template>
        </el-table-column>
        <el-table-column prop="code" label="编码">
            <template v-slot="scope">{{ scope.row.material.code }}</template>
        </el-table-column>
        <el-table-column prop="cCode" label="自定义编码">
            <template v-slot="scope">{{ scope.row.material.cCode }}</template>
        </el-table-column>
        <el-table-column prop="model" label="型号">
            <template v-slot="scope">{{ scope.row.material.model }}</template>
        </el-table-column>
        <el-table-column prop="recycleTStr" label="类别">
            <template v-slot="scope">{{ scope.row.material.recycleTStr }}</template>
        </el-table-column>
        <el-table-column prop="unit" label="单位">kg</el-table-column>
        <el-table-column prop="amount" label="出库量"></el-table-column>
        <el-table-column prop="price" label="单价（元）"></el-table-column>
        <el-table-column label="金额（元）">
            <template v-slot="scope">{{ scope.row.amount * scope.row.price }}</template>
        </el-table-column>
    </el-table>
</template>

<script>
    export default {
        props: {
            // orderId: String
            list: Array
        }
    };
</script>
<style></style>
