<template>
    <div>
        <el-radio-group size="mini" v-show="!hideTop" v-model="status" class="page_header" @change="change">
            <el-radio-button v-for="r in countList" :key="r.status" :label="r.status">{{ r.statusStr }}（{{ r.count }}）</el-radio-button>
        </el-radio-group>
        <transition>
            <component @switch="switchPage" :is="page"></component>
        </transition>
    </div>
</template>

<script>
    import NoSubmit from "./noSubmit.vue";
    import Submit from "./submit.vue";
    import Detail from "./detail.vue";
    export default {
        data() {
            return {
                status: "0",
                page: "NoSubmit",

                hideTop: false,

                option: null,
                countList: [],
                uUnitList: []
            };
        },
        watch: {
            page() {
                if (this.page === "NoSubmit" || this.page === "Submit") {
                    this.hideTop = false;
                } else {
                    this.hideTop = true;
                }
            }
        },
        methods: {
            initCount() {
                this.$http.post("/bill/verify/countList").then(res => {
                    this.countList = res.detail;
                });
            },
            initDicts() {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "1"
                    })
                    .then(res => {
                        this.uUnitList = res.detail;
                    });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.dUnitList = res.detail;
                    });
            },
            change() {
                if (this.status === "0") {
                    this.page = "NoSubmit";
                } else {
                    this.page = "Submit";
                }
            },
            switchPage(name, option) {
                this.page = name;
                this.option = option;
            }
        },
        created() {
            this.initDicts();
            this.initCount();
        },
        components: {
            NoSubmit,
            Submit,
            Detail
        }
    };
</script>
<style scoped>
    .page_header {
        margin-bottom: 15px;
    }
</style>
