<template>
    <div>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="出库单据编号" prop="receiptNo">
                <el-input v-model="form.receiptNo"></el-input>
            </jy-query-item>
            <jy-query-item label="利用单位" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in uUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="创建人" prop="cUserNa">
                <el-input v-model="form.cUserNa"></el-input>
            </jy-query-item>
            <jy-query-item label="是否收款" prop="hasRAmount">
                <el-select v-model="form.hasRAmount">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已收款" value="2"></el-option>
                    <el-option label="部分收款" value="1"></el-option>
                    <el-option label="未收款" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="创建时间" prop="createT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.createT"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="对账确认时间" prop="verifyT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.verifyT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesSettleCenterReceiveBillList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesSettleCenterReceiveBillReset')">重置</el-button>
                <el-row>
                    <export-excel
                        v-if="btnexist('wastesSettleCenterReceiveBillExport') && false"
                        baseUrl="/iwastes-admin"
                        url="/bill/verify/export"
                        :params="form"
                        fileName="已结算应收对账单"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="tableData">
                <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                <jy-table-column label="对账单号" prop="receiptNo" show-overflow-tooltip></jy-table-column>
                <jy-table-column label="利用单位" prop="unitNa" show-overflow-tooltip></jy-table-column>
                <jy-table-column label="出库单数量" prop="ioStockNum"></jy-table-column>
                <jy-table-column label="创建人" prop="cUserNa"></jy-table-column>
                <jy-table-column label="创建时间" prop="createT" width="150"></jy-table-column>
                <jy-table-column label="对账确认时间" prop="verifyT" width="150"></jy-table-column>
                <jy-table-column label="是否收款">
                    <template v-slot="scope">{{ formatPayee(scope.row) }}</template>
                </jy-table-column>
                <jy-table-column label="应收总计（元）" prop="amount"></jy-table-column>
                <jy-table-column label="已收金额（元）" prop="rAmount"></jy-table-column>
                <jy-table-column label="剩余应收金额（元）">
                    <template v-slot="scope">{{ formatRestMoney(scope.row.amount, scope.row.rAmount) }}</template>
                </jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <receive ref="receive" @update="getList"></receive>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import Receive from "@/components/pages/admin/settleCenter/receiveBill/Receive.vue";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [btnMixins],
        methods: {
            oncheck() {
                this.pageIndex = 1;
                this.getList();
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.oncheck();
            },
            getList() {
                this.loading = true;
                this.$http
                    .post("/bill/verify/queryPage", {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        ...this.form,
                        verify: "1",
                        createTStart: this.$util.addTime(this.form.createT && this.form.createT[0], true),
                        createTEnd: this.$util.addTime(this.form.createT && this.form.createT[1]),
                        verifyTStart: this.$util.addTime(this.form.verifyT && this.form.verifyT[0], true),
                        verifyTEnd: this.$util.addTime(this.form.verifyT && this.form.verifyT[1]),
                        createT: undefined,
                        verifyT: undefined
                    })
                    .then(res => {
                        this.tableData = res.detail.list;
                        this.total = res.detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            handleView(row) {
                this.$emit("switch", "Detail", {
                    ...row,
                    source: "Submit",
                    view: true
                });
            },
            handleReceive(row) {
                this.$refs.receive.init(row);
            },
            setOperateList() {
                let l = [
                    {
                        name: "查看",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return this.btnexist("wastesSettleCenterReceiveBillView");
                        }
                    },
                    {
                        name: "收款",
                        icon: "el-icon-edit-outline",
                        fun: this.handleReceive,
                        isShow: row => {
                            return this.btnexist("wastesSettleCenterReceiveBillReceive") && row.amount !== row.rAmount;
                        }
                    }
                ];
                this.operateList = l;
            },
            formatUUnit(id) {
                const w = this.uUnitList.find(v => v.unitId === id);
                console.log(this.uUnitList);
                return w ? w.cname : "-";
            },
            formatPayee(row) {
                let amount = row.amount || 0
                let rAmount = row.rAmount || 0
                if (amount <= rAmount && rAmount) {
                    return "已收款";
                } else if (rAmount != 0) {
                    return "部分收款";
                } else {
                    return "未收款";
                }
            },
            formatRestMoney(total, rest) {
                return (total - rest).toFixed(2);
            }
        },
        mounted() {
            this.getList();
        },
        created() {
            this.setOperateList();
        },
        computed: {
            uUnitList() {
                return this.$parent.uUnitList;
            }
        },
        data() {
            return {
                loading: false,
                tableData: [],
                total: 0,

                form: {
                    receiptNo: "",
                    unitId: "",
                    cUserNa: "",
                    hasRAmount: "",
                    createT: [],
                    verifyT: []
                },

                pageIndex: 1,
                pageSize: 10,
                operateList: [],

                btnMenuId: "wastesSettleCenterReceiveBill"
            };
        },
        components: {
            ExportExcel,
            Receive
        }
    };
</script>
<style></style>
