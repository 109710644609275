<template>
    <div>
        <el-page-header @back="backMain" :content="'应收对账 > ' + title"></el-page-header>
        <el-form :model="model" :rules="rules" class="detail_form" size="small" label-position="right" label-width="130px">
            <div class="form_item">
                <div class="head_title">基本信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="对账单号" prop="receiptNo">
                            <el-input v-if="add" :value="model.receiptNo" disabled></el-input>
                            <span v-else>{{ this.model.receiptNo }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="利用单位" prop="unitId">
                            <el-select v-if="add" v-model="model.unitId" @change="changeUnit">
                                <el-option v-for="u in uUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                            </el-select>
                            <span v-else>{{ formatUUnit(this.model.unitId) }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item v-if="view" label="创建人">{{ this.model.cUserNa }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item v-if="view" label="创建时间">{{ this.model.createT }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item v-if="view" label="应收总计">{{ this.model.amount }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item v-if="view && !confirm" label="对账确认时间">{{ this.model.verifyT }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item v-if="view && !confirm" label="是否收款">{{ formatPayee() }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item v-if="view && !confirm" label="已收金额">{{ this.model.rAmount }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item v-if="view && !confirm" label="剩余应收金额">{{ formatRestMoney(this.model.amount, this.model.rAmount) }}</el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">
                    <span>应收费用</span>
                    <el-button v-if="edit || add" size="small" @click="addItem">添加</el-button>
                </div>
                <el-table :data="dataList">
                    <el-table-column type="expand">
                        <template v-slot="props">
                            <order-detail :list="props.row.detailList"></order-detail>
                        </template>
                    </el-table-column>
                    <el-table-column label="出库单据编号" prop="receiptNo"></el-table-column>
                    <el-table-column label="出库时间" prop="createT"></el-table-column>
                    <el-table-column label="出库分拣中心" prop="unitId">
                        <template v-slot="scope">{{ formatUnit(scope.row.unitId) }}</template>
                    </el-table-column>
                    <el-table-column label="利用单位" prop="uUnitId">
                        <template v-slot="scope">{{ formatUUnit(scope.row.uUnitId) }}</template>
                    </el-table-column>
                    <el-table-column label="利用方式" prop="usage"></el-table-column>
                    <el-table-column label="合计金额（元）">
                        <template v-slot="scope">{{ formatTAmount(scope.row.detailList) }}</template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="form_item" v-if="view && !confirm">
                <div class="head_title">收款记录</div>
                <el-table :data="payeeList">
                    <el-table-column label="序号" type="index"></el-table-column>
                    <el-table-column label="收款金额" prop="amount"></el-table-column>
                    <el-table-column label="交易渠道" prop="payW">
                        <template v-slot="scope">{{ formatPayW(scope.row.payW) }}</template>
                    </el-table-column>
                    <el-table-column label="备注" prop="remark"></el-table-column>
                    <el-table-column label="登记人" prop="cUserNa"></el-table-column>
                    <el-table-column label="登记时间" prop="createT"></el-table-column>
                </el-table>
            </div>
        </el-form>

        <div class="bill_buttons" v-if="confirm || edit || add">
            <el-button v-if="edit || add" size="small" type="primary" @click="makeSure">确定</el-button>
            <el-button v-if="confirm" size="small" type="primary" @click="makeSureBill">对账确认</el-button>
            <el-button size="small" @click="backMain">取消</el-button>
        </div>

        <account ref="account" @select="selectAccount"></account>
    </div>
</template>

<script>
    import { cloneDeep } from "lodash";
    import Account from "@/components/pages/admin/settleCenter/receiveBill/Account.vue";
    import OrderDetail from "@/components/pages/admin/settleCenter/receiveBill/OrderDetail.vue";
    export default {
        mounted() {
            const option = this.$parent.option;
            if (option.add) {
                this.model.receiptNo = this.generateReceiptNo();
                this.add = true;
            } else {
                this.model = cloneDeep(option);

                if (option.confirm) {
                    this.title = "对账确认";
                    this.confirm = true;
                    this.view = true;
                } else if (option.edit) {
                    this.title = "编辑";
                    this.edit = true;
                } else if (option.view) {
                    this.getMoneyRecord();
                    this.view = true;
                    this.title = "查看";
                }
                this.getList(this.model.tallyId);
            }
            this.source = option.source;
        },
        methods: {
            formatTAmount(list) {
                if (list && list.length) {
                    return list.reduce((total, cur) => {
                        return total + cur.amount * cur.price;
                    }, 0);
                } else {
                    return 0;
                }
            },
            formatPayee() {
                const { amount, rAmount } = this.model
                if (!rAmount) {
                    return '未收款'
                } else if (rAmount && (rAmount - amount < 0)) {
                    return '部分收款'
                } else if (rAmount && (rAmount - amount) >= 0) {
                    return '已收款'
                }
            },
            formatPayW(val) {
                const p = this.payWList.find(v => v.value == val);
                return p ? p.label : "-";
            },
            formatRestMoney(total, rest) {
                return total - rest;
            },
            getList(id) {
                this.$http.post("/bill/verify/batchQueryBillByTallyIds", id).then(res => {
                    this.dataList = res.detail;
                });
            },
            backMain() {
                this.$emit("switch", this.source);
            },
            getMoneyRecord() {
                this.$http.post("/bill/verify/getReceiveListByTallyId", this.model.tallyId).then(res => {
                    this.payeeList = res.detail;
                });
            },
            makeSureBill() {
                this.$http.post("/bill/verify/batchConfirmVerifyBill", this.model.tallyId).then(res => {
                    this.$message.success({
                        message: res.msg
                    });
                    this.backMain();
                });
            },
            makeSure() {
                let url = "/bill/verify/add";
                if (this.model.tallyId) {
                    url = "/bill/verify/update";
                }
                this.$http
                    .post(url, {
                        ...this.model,
                        ioStockIds: this.dataList.map(v => v.ioStockId)
                    })
                    .then(res => {
                        this.$message.success({
                            message: res.msg
                        });
                        this.backMain();
                    });
            },
            formatUUnit(id) {
                const w = this.uUnitList.find(v => v.unitId === id);
                return w ? w.cname : "-";
            },
            formatUnit(key) {
                const u = this.dUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            changeUnit(unitId) {
                const u = this.uUnitList.find(v => v.unitId === unitId);
                this.model.unitId = u.unitId;
                this.model.orgId = u.orgId;
                this.model.mOrgId = u.mOrgId;
                this.dataList = [];
            },
            addItem() {
                if (this.model.unitId) {
                    this.$refs.account.init(this.model.unitId);
                } else {
                    this.$message.warning({
                        message: "请先选择利用单位"
                    });
                }
            },
            selectAccount(list) {
                this.dataList = list;
            },
            generateReceiptNo() {
                return "DZ" + this.$util.parseTime(new Date(), "yyyyMMddhhmmss");
            }
        },
        computed: {
            uUnitList() {
                return this.$parent.uUnitList;
            },
            dUnitList() {
                return this.$parent.dUnitList;
            }
        },
        data() {
            return {
                title: "新增",
                source: "",
                model: {
                    unitId: "",
                    receiptNo: "",
                    ioStockIds: []
                },
                rules: {
                    unitId: [{ required: true, message: "利用单位不为空", trigger: "change" }]
                },
                dataList: [],
                payeeList: [],
                add: false, //新增编辑
                edit: false,
                view: false,
                confirm: false,

                payWList: [
                    {
                        label: "对公转账",
                        value: 0
                    },
                    {
                        label: "微信",
                        value: 1
                    },
                    {
                        label: "支付宝",
                        value: 2
                    },
                    {
                        label: "现金",
                        value: 3
                    }
                ]
            };
        },
        components: { Account, OrderDetail }
    };
</script>
<style lang="scss" scoped>
    .bill_buttons {
        text-align: center;
    }
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;

                .el-button {
                    float: right;
                }
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }
        }
    }
</style>
