<template>
    <div>
        <jy-query ref="form" :model="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="对账单号" prop="receiptNo">
                <el-input v-model="form.receiptNo"></el-input>
            </jy-query-item>
            <jy-query-item label="利用单位" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in uUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="创建人" prop="cUserNa">
                <el-input v-model="form.cUserNa"></el-input>
            </jy-query-item>
            <jy-query-item label="创建时间" prop="createT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.createT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesSettleCenterReceiveBillList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesSettleCenterReceiveBillReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" @click="add" v-if="btnexist('wastesSettleCenterReceiveBillInsert')">新增</el-button>
                    <el-button size="small" type="primary" @click="batchConfirm" v-if="btnexist('wastesSettleCenterReceiveBillVerify')">对账确认</el-button>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table ref="billTable" :data="tableData">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column type="selection"></jy-table-column>
                <jy-table-column label="出库单据编号" prop="receiptNo" min-width="170"></jy-table-column>
                <jy-table-column label="出库时间" prop="createT" min-width="160"></jy-table-column>
                <jy-table-column label="利用单位" prop="unitNa" min-width="120"></jy-table-column>
                <jy-table-column label="出库单数量" prop="ioStockNum" min-width="100"></jy-table-column>
                <jy-table-column label="应收总计（元）" prop="amount" min-width="100"></jy-table-column>
                <jy-table-column label="制单人" prop="cUserNa" min-width="120"></jy-table-column>
                <jy-table-column label="创建时间" prop="createT" min-width="160"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <recycle-types-tree ref="recycle" @addInstitutions="addRecycleType"></recycle-types-tree>
    </div>
</template>

<script>
    import RecycleTypesTree from "@/components/pages/admin/common/dialog-tree/recycleTypes_tree.vue";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [btnMixins],
        methods: {
            addRecycleType(data) {
                this.form.recycleTNa = data.cName;
                this.form.recycleT = data.recycleTId;
            },
            // 选择机构树
            selecrecycleTs() {
                this.$refs.recycle.init();
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.oncheck();
            },
            getList() {
                this.loading = true;
                console.log(this.$util);
                this.$http
                    .post("/bill/verify/queryPage", {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        ...this.form,
                        verify: "0",
                        createTStart: this.$util.addTime(this.form.createT && this.form.createT[0], true),
                        createTEnd: this.$util.addTime(this.form.createT && this.form.createT[1]),
                        createT: undefined
                    })
                    .then(res => {
                        this.tableData = res.detail.list;
                        this.total = res.detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            add() {
                this.$emit("switch", "Detail", {
                    add: true,
                    source: "NoSubmit"
                });
            },
            formatUUnit(id) {
                const w = this.uUnitList.find(v => v.unitId === id);
                return w ? w.cname : "-";
            },
            handlePrice(row) {
                this.$emit("switch", "Detail", {
                    ...row,
                    confirm: true,
                    source: "NoSubmit"
                });
            },
            handleEdit(row) {
                this.$emit("switch", "Detail", {
                    ...row,
                    edit: true,
                    source: "NoSubmit"
                });
            },
            batchConfirm() {
                const list = this.$refs.billTable.selection;
                if (list.length) {
                    this.$confirm("确定对列表中选中的对账单批量对账确认?", "批量对账确认", {
                        confirmButtonText: "对账确认",
                        cancelButtonText: "取消",
                        type: "warning"
                    })
                        .then(() => {
                            this.$http.post("/bill/verify/batchConfirmVerifyBill", list.map(v => v.tallyId).join(",")).then(res => {
                                this.$message.success({
                                    message: res.msg
                                });
                                this.getList();
                            });
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "已取消删除"
                            });
                        });
                } else {
                    this.$message.warning({
                        message: "请选择要确认对账的列"
                    });
                }
            },
            handleDelete(row) {
                this.$confirm("是否删除该未结算的应收对账单，删除不可恢复?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.$http.post("/bill/verify/delete", row.tallyId).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            setOperateList() {
                let l = [
                    {
                        name: "对账确认",
                        icon: "el-icon-edit-outline",
                        fun: this.handlePrice,
                        isShow: () => {
                            return this.btnexist("wastesSettleCenterReceiveBillVerify");
                        }
                    },
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleEdit,
                        isShow: () => {
                            return this.btnexist("wastesSettleCenterReceiveBillUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesSettleCenterReceiveBillDelete");
                        }
                    }
                ];
                this.operateList = l;
            }
        },
        computed: {
            uUnitList() {
                return this.$parent.uUnitList;
            }
        },
        created() {
            this.setOperateList();
        },
        mounted() {
            this.getList();
        },
        data() {
            return {
                loading: false,
                tableData: [],
                total: 0,

                form: {
                    receiptNo: "",
                    unitId: "",
                    cUserNa: "",
                    createT: []
                },

                pageIndex: 1,
                pageSize: 10,

                operateList: [],

                btnMenuId: "wastesSettleCenterReceiveBill"
            };
        },
        components: {
            RecycleTypesTree
        }
    };
</script>
<style></style>
